import React, { FC, memo } from 'react';

import { Section } from '@keaze/web/components/section';
import { CardGroup } from '@keaze/web/components/cardGroup';
import { FeaturedPropertiesProps } from './featuredProperties.types';

export const FeaturedProperties: FC<FeaturedPropertiesProps> = memo(
  ({ data }) => (
    <Section>
      <CardGroup
        title="Featured properties"
        titlePosition="center"
        data={data}
      />
    </Section>
  )
);
